//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      clearHeight: false,
      lightBlueBg:  false
    };
  },
  watch: {
    $route(newValue, oldValue) {
      console.log(newValue)
      if (
        newValue.name === "distributor-train-front-home" ||
        newValue.name === "distributor-train-front-pay-success" ||
        newValue.name === "air-ticket"
      ) {
        this.clearHeight = true;
      } else {
        this.clearHeight = false;
      }


      // if (newValue.name === 'air-list' || newValue.name === 'air-ticket-admin-ticketChanging' || newValue.name === 'air-ticket-admin-airDetails' || newValue.name === 'air-pay') {
        if (newValue.name === 'air-list' || newValue.name === 'air-ticket-admin-ticketChanging') {
        this.lightBlueBg = true
      } else {
        this.lightBlueBg = false
      }
    },
  },
  created() {
    if (
      this.$route.name === "distributor-train-front-home" ||
      this.$route.name === "distributor-train-front-pay-success" ||
      this.$route.name === "air-ticket"
    ) {
      this.clearHeight = true;
    } else {
      this.clearHeight = false;
    }


    // if (this.$route.name === 'air-list' || this.$route.name === 'air-ticket-admin-ticketChanging' || this.$route.name === 'air-ticket-admin-airDetails' || this.$route.name === 'air-pay') {
      if (this.$route.name === 'air-list' || this.$route.name === 'air-ticket-admin-ticketChanging') {
      this.lightBlueBg = true
    } else {
      this.lightBlueBg = false
    }
  },
};
