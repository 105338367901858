//
//
//
//
//
//
//
//

import Head from './component/head/index.vue'
import Main from './component/main'
import Footer from './component/footer/index.vue'
export default {
    components: {Head, Main, Footer}
}
