import Q from "q";

import redirect_to_login_page from "@/lib/common-service/redirect_to_login_page";
import redirect_to_user_back_home from "@/lib/common-service/redirect_to_user_back_home";
import web_common_source_getPermissions from "@/lib/common-service/web_common_source_getPermissions";
import web_common_tourOrder_getTravelAccount from "@/lib/data-service/default/web_common_tourOrder_getTravelAccount";
import logout from "@/lib/data-service/default/logout";
import PubSub from "pubsub-js";

import web_common_area_getStoreAreaTree from "@/lib/data-service/default/web_common_area_getStoreAreaTree";
import getLocalCity from "@/lib/common-service/getLocalCity";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";

// 获取用户信息
import web_common_getCurrentUserAuditStatus from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      travelKeywords: "",
      siteCityInfo: {
        upBoxStatu: false, //城市显示判断
        siteList: [], //城市列表
      },
      data_web_common_source_getPermissions: {
        userName: ``,
      }, // 用户
      data_web_common_tourOrder_getTravelAccount: {
        travelAccountManager: ``,
        phone: ``,
        fromCity: "",
        developer: '',
        developerPhone:''
      }, // 经理
      navShow: false, // 是否显示导航菜单栏
      fromCity: "",
      upBoxinitial: 0, //地址搜索初始状态
      userInfo: {}, // 用户信息
    };
  },
  components: {},
  created() {
    web_common_getCurrentUserAuditStatus()
      .then((result) => {
        const params = { ...result, ...result.userVo };
        this.$store.commit("set_user_info", params);
      })
      .catch((err) => {});
    this.setScroll(this.$route.path);

    PubSub.subscribe(
      "travelKeywords",
      (msg, data) => (this.travelKeywords = data)
    );

    const __this = this;
    // 获取用户资料，判断权限
    get_user_info().then((res) => {
      this.userInfo = res;
    });
    // this.getCity()
    web_common_source_getPermissions().then(function(data) {
      __this.data_web_common_source_getPermissions = data;
    });

    web_common_tourOrder_getTravelAccount().then(function(data) {
      __this.data_web_common_tourOrder_getTravelAccount = data;
      __this.data_web_common_tourOrder_getTravelAccount.fromCity =
        data.cityName;
      __this.fromCity = data.cityName;
      __this.setFormCity(data.cityName);
      __this.$store.commit("update_localCity", {
        cityName: data.cityName,
        cityId: Number(data.areaCity),
      });
    });

    this.getUserLocalCity();
  },
  mounted() {},
  activated() {},
  deactivated() {},
  destroyed() {
    // console.log('sessionStorage', sessionStorage)
    // sessionStorage.removeItem('localCity')
    // console.log(sessionStorage.getItem('localCity'))
    // sessionStorage.clear()
    /*let localCity = {
      cityId: '',
      cityName: '',
      cityCode: '',
    };
    this.$store.commit("set_local_city", localCity);*/
  },
  watch: {
    $route(newValue, oldValue) {
      this.setScroll(newValue.path)
    }
  },
  computed: {
    ...mapGetters(["baseLayout"]),
  },
  filters: {},
  methods: {
    // 获取当前的所在城市
    getUserLocalCity() {
      let cityName = sessionStorage.getItem("localCity");
      if (cityName) {
        this.$store.commit("set_local_city", JSON.parse(cityName));
      } else {
        getLocalCity({ clear_cache: true })
          .then((res) => {
            let localCity = {
              cityId: res.areaId,
              cityName: res.cityName,
              cityCode: res.cityCode,
            };
            this.$store.commit("set_local_city", localCity);
            sessionStorage.setItem("localCity", JSON.stringify(localCity));
            // this.$emit('selectCity', localCity)
          })
          .catch(() => {});
      }
    },
    // 点击切换城市
    toChangeCity() {
      this.$router.push({
        name: "distributor-tour-front-select-city",
      });
    },
    async __btn_tour_home_click() {
      this.travelKeywords = "";
      this.$router.push({
        name: "distributor-tour-front-home",
      });
    },
    headShow() {
      let _this = this;
      var c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 125) {
        _this.navShow = true;
      } else {
        _this.navShow = false;
      }
    },

    update_isHeadSearch(query) {
      if (this.$route.name !== "distributor-tour-front-search") {
        this.$router.push({
          name: "distributor-tour-front-search",
          query,
        });
      } else {
        this.$store.commit("update_isHeadSearch", true);
        PubSub.publish("head", query);
      }
    },
    getCity() {
      web_common_area_getStoreAreaTree().then((res) => {
        this.siteCityInfo.siteList = res.storeAreaTreeResults;
      });
    },
    getItem(val) {
      this.data_web_common_tourOrder_getTravelAccount.fromCity = val.areaName;
      this.fromCity = val.areaName;
      this.setFormCity(this.fromCity);
    },
    setFormCity(val) {
      this.$store.commit("SET_SELECTCITY", val);
    },
    __btn_admin_click() {
      const __this = this;
      __this.travelKeywords = "";
      redirect_to_user_back_home();
    },

    __btn_logout_click() {
      const __this = this;
      Q.when()
        .then(function() {
          return logout();
        })
        .then(function(data) {
          // 删除12306的账号本地缓存
          localStorage.removeItem("userInfoVo");
          localStorage.removeItem("trainLoginParams");
          // 删除定位
          sessionStorage.removeItem("localCity");
          let localCity = {
            cityId: "",
            cityName: "",
            cityCode: "",
          };
          __this.$store.commit("set_local_city", localCity);

          redirect_to_login_page();
        })
        .catch(function(ex) {
          console.error(ex);
          if (ex) {
            if (ex.stack) {
              console.error(ex.stack);
            }
          }
          return Q.reject(ex);
        });
    },
    setScroll(val) {
      let arr = [
        "/air-ticket",
        "/hotel/",
        "/distributor/tour/front/search",
        "/distributor/tour/front/home",
      ];
      if (arr.indexOf(val) !== -1) {
        window.addEventListener("scroll", this.headShow);
      } else {
        document.documentElement.scrollTop = 0;
        setTimeout(() => {
          window.removeEventListener("scroll", this.headShow, false);
        }, 100);
      }
    },
    ...mapMutations(["SET_LOCAL_CITY"]),
    to_home() {
      if (this.$route.name !== "distributor-tour-front-home") {
        this.$router.push({
          name: "distributor-tour-front-home",
        });
      }
    },
  },
};
